<template>
  <div class="bg-gray">
    <onePay
      v-if="stripeEnable"
      ref="onePay"
      :is-show="outerVisible"
      :order-id="orderId"
      :pay-type="2"
    />
    <!-- loadding加载 -->
    <div v-show="loading" class="loading">
      <div class="loading-load">
        <a-spin size="large" tip="loading..." />
      </div>
    </div>
    <div class="back">
      <a-icon type="arrow-left" @click="goBack" />
      <span>{{ orderSn }}</span>
      <div class="cancel">
        <a-button
          v-if="orderInfo.status < 2"
          size="large"
          @click="cancel"
        >Cancel this order</a-button>
        <a-button
          v-if="isOnHold && orderInfo.status < 3"
          style="margin-left:20px"
          size="large"
          @click="onHold"
        >Set On Hold</a-button>
      </div>
    </div>
    <div class="unpay">
      <div class="p-head">
        <div class="h-title">order details</div>
        <div class="s-title" @click="isShowEditMask = true">
          Shipping info
          <a-icon v-if="orderInfo.status < 2" type="edit" />
        </div>
      </div>
      <div class="content">
        <div class="product">
          <div v-if="orderlistStatus1.length > 0" class="product-list">
            <OrderItem
              v-for="(item, index) in orderlistStatus1"
              :key="index"
              :item="item"
            />
            <a-button class="statu pending">Pending</a-button>
          </div>
          <div v-if="orderlistStatus2.length > 0" class="product-list">
            <OrderItem
              v-for="(item, index) in orderlistStatus2"
              :key="index"
              :item="item"
            />
            <a-button class="statu confirmed">Confirmed</a-button>
          </div>
          <div v-if="orderlistStatus3.length > 0" class="product-list">
            <OrderItem
              v-for="(item, index) in orderlistStatus3"
              :key="index"
              :item="item"
            />
            <a-button class="statu confirmed">In production</a-button>
          </div>
          <div v-if="orderlistStatus4.length > 0" class="product-list">
            <OrderItem
              v-for="(item, index) in orderlistStatus4"
              :key="index"
              :item="item"
            />
            <a-button class="statu fulfilled">Fulfilled</a-button>
          </div>
          <div v-if="orderlistStatus5.length > 0" class="product-list">
            <OrderItem
              v-for="(item, index) in orderlistStatus5"
              :key="index"
              :item="item"
            />
            <a-button class="statu onhold">On hold</a-button>
          </div>
          <div v-if="orderlistStatus6.length > 0" class="product-list">
            <OrderItem
              v-for="(item, index) in orderlistStatus6"
              :key="index"
              :item="item"
            />
            <a-button class="statu onhold">Cancelled</a-button>
          </div>
          <div class="c">
            <a-button size="large" @click="timeLine">
              Timeline
              <a-icon type="column-height" />
            </a-button>
            <div v-show="isTimeLine" class="timeLine">
              <a-timeline>
                <a-timeline-item
                  v-for="(item, index) in orderHisInfos"
                  :key="index"
                >
                  {{ item.addDate }}
                  <p>{{ item.status | dictFilter(enums.orderStatusEnum) }}</p>
                </a-timeline-item>
              </a-timeline>
            </div>
          </div>
        </div>
        <div class="shop">
          <ul>
            <li>
              <span>Name:</span>
              <span>{{ orderInfo.customerName }}</span>
            </li>
            <li>
              <span :class="{ 'color-red': colorRed }">Phone number:</span>
              <span :class="{ 'color-red': colorRed }">
                {{ orderInfo.addressToPhone }}
              </span>
            </li>
            <li>
              <span>Email:</span>
              <span>{{ orderInfo.addressToEmail }}</span>
            </li>
            <li>
              <span>Country:</span>
              <span>{{ orderInfo.addressToCountry }}</span>
            </li>
            <li>
              <span>Province:</span>
              <span>{{ orderInfo.addressToRegion }}</span>
            </li>
            <li>
              <span>City:</span>
              <span>{{ orderInfo.addressToCity }}</span>
            </li>
            <li>
              <span>Address1:</span>
              <span>{{ orderInfo.addressToAddress1 }}</span>
            </li>
            <li v-if="orderInfo.addressToAddress2">
              <span>Address2:</span>
              <span>{{ orderInfo.addressToAddress2 }}</span>
            </li>
            <li>
              <span>Zip code</span>
              <span>{{ orderInfo.addressToZip }}</span>
            </li>
            <li>
              <span>note</span>
              <span>{{ orderInfo.remark }}</span>
            </li>
          </ul>
          <div v-if="orderInfo.status === enums.orderStatusEnum.PENDING.value" class="shopMethod">
            <div class="s-title sm-title">Shipping method</div>
            <a-radio-group v-model="logisticsMethodId" style="margin: 10px 0" @change="changeMethod">
              <a-radio v-for="item in priceInfos" :key="item.logisticsMethodId" :value="item.logisticsMethodId">
                {{ item.logisticsMethodName }}
              </a-radio>

            </a-radio-group>
            <p>Note:A Remote Area Surcharge is applied per shipment when the  international delivery destination or pick up location is remote. </p>
          </div>
          <div class="s-title sm-title">Billing</div>
          <ul>
            <li>
              <span>Product cost:</span>
              <span>${{ selectPriceInfo.production ||orderInfo.goodsTotalPrice }}</span>
            </li>
            <li>
              <span>Shipping:</span>
              <span>${{ selectPriceInfo.shipping||orderInfo.logisticsTotalPrice }}</span>
            </li>
            <li>
              <span>Discount:</span>
              <span>${{ selectPriceInfo.discount|| orderInfo.goodsDiscount }}</span>
            </li>
            <li>
              <span>Branding:</span>
              <span>${{ selectPriceInfo.branding || orderInfo.fittingsPrice ? orderInfo.fittingsPrice : 0 }}</span>
            </li>
            <li style="border-top: 1px solid #ddd;padding-top: 5px">
              <span>Total costs:</span>
              <span>${{ selectPriceInfo.total || orderInfo.totalPrice }}</span>
            </li>
            <div style="margin: 20px 0;">
              <a-button
                v-if="stripeEnable && orderInfo.status === enums.orderStatusEnum.PENDING.value"
                class="pay-btn"
                type="primary"
                @click="pay()"
              >Pay With Credit Card</a-button>
            </div>
            <div
              v-if="paypalEnable"
              v-show="orderInfo.status === enums.orderStatusEnum.PENDING.value"
              class="paypal"
            >
              <a-button>Pay With PayPal</a-button>
              <div id="paypal-button-container2" />
            </div>
          </ul>
        </div>
      </div>
      <div v-show="isShowEditMask" class="edit-shopping">
        <div class="edit-warp">
          <a-form-model
            ref="ruleForm"
            :model="ruleForm"
            :rules="rules"
            v-bind="layout"
          >
            <a-row type="flex">
              <a-col class="gutter-row" :span="12">
                <a-form-model-item
                  has-feedback
                  label="First name"
                  prop="firstName"
                >
                  <a-input
                    v-model="ruleForm.firstName"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
              <a-col class="gutter-row" :span="12">
                <a-form-model-item
                  has-feedback
                  label="Last name"
                  prop="lastName"
                >
                  <a-input
                    v-model="ruleForm.lastName"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col class="gutter-row" :span="12">
                <a-form-model-item has-feedback label="Email (optional)">
                  <a-input
                    v-model="ruleForm.email"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
              <a-col class="gutter-row" :span="12">
                <a-form-model-item has-feedback label="Phone" prop="phone">
                  <a-input
                    v-model="ruleForm.phone"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col class="gutter-row" :span="12">
                <a-form-model-item has-feedback label="Country" prop="country">
                  <a-select
                    v-model="ruleForm.country"
                    autocomplete="off"
                    show-search
                    aceholder="Select a country"
                    option-filter-prop="children"
                    :filter-option="filterOption"
                    @change="choiceCountrys"
                  >
                    <a-select-option
                      v-for="(item, index) in countrysList"
                      :key="index"
                      :value="item.id"
                    >
                      {{ item.enName }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
              <a-col class="gutter-row" :span="12">
                <a-form-model-item
                  has-feedback
                  label="State / Province"
                  prop="state"
                >
                  <a-select
                    v-show="provinceList.length > 0"
                    v-model="ruleForm.state"
                    autocomplete="off"
                    @change="choiceProvince"
                  >
                    <a-select-option
                      v-for="(item, index) in provinceList"
                      :key="index"
                      :value="item.enName"
                    >
                      {{ item.enName }}
                    </a-select-option>
                  </a-select>
                  <a-input
                    v-show="!provinceList.length"
                    v-model="ruleForm.state"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col class="gutter-row" :span="12">
                <a-form-model-item has-feedback label="Address" prop="address">
                  <a-input
                    v-model="ruleForm.address"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
              <a-col class="gutter-row" :span="12">
                <a-form-model-item
                  has-feedback
                  label="Address2"
                  prop="address2"
                >
                  <a-input
                    v-model="ruleForm.address2"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-row type="flex">
              <a-col class="gutter-row" :span="12">
                <a-form-model-item has-feedback label="City" prop="city">
                  <a-input
                    v-model="ruleForm.city"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
              <a-col class="gutter-row" :span="12">
                <a-form-model-item
                  has-feedback
                  label="Postal/ZIP Code"
                  prop="postal"
                >
                  <a-input
                    v-model="ruleForm.postal"
                    type="text"
                    autocomplete="off"
                  />
                </a-form-model-item>
              </a-col>
            </a-row>
            <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
              <a-button @click="isShowEditMask = false">Cancel</a-button>
              <a-button
                type="primary"
                style="margin-left: 10px"
                @click.stop="submitForm('ruleForm')"
              >Continue</a-button>
            </a-form-model-item>
          </a-form-model>
        </div>
      </div>
    </div>
    <!-- 查看图片弹框 -->
    <div v-if="isPreview" class="design-mask">
      <div class="design-dialog">
        <div class="design-close" @click="isPreview = false">
          <img src="@/assets/images/custom/dialog-close.png" alt="">
        </div>
        <div class="dialog-main">
          <div class="dialog-left">
            <img src="@/assets/images/custom/dialog-left.png" alt="" @click="handlePre">
          </div>
          <div class="dialog-con">
            <img :src="preViewImg" alt="">
          </div>
          <div class="dialog-left">
            <img src="@/assets/images/custom/dialog-right.png" alt="" @click="handleNext">
          </div>
        </div>
        <div class="dialog-pages">
          <div class="dialog-page">
            <img
              v-for="(item, index) in previewList"
              :key="index"
              :class="{ actived: previewIndex == index }"
              :src="item"
              @click="changePreview(index)"
            >
          </div>
        </div>
      </div>
    </div>
    <!-- <div v-if="isPreview" class="dialog">
      <div class="d-content">
        <a-row style="height: 100%">
          <a-col :span="15">
            <div class="d-img">
              <img :src="preViewImg">
              <a-button type="primary" @click="closePreview">
                <a-icon type="left" />Return
              </a-button>
            </div>
          </a-col>
          <a-col :span="9" style="position: absolute;height: 100%;right: 0;">
            <ul class="clearfix">
              <li
                v-for="(item, index) in previewList"
                :key="index"
                :class="{ actived: previewIndex == index }"
              >
                <img :src="item" @click="changePreview(index)">
              </li>
            </ul>
          </a-col>
        </a-row>
      </div>
    </div> -->
    <a-modal v-model="cancelVisible" @ok="cancelConfirm">
      <p>
        Please confirm if you need to submit the order [{{ orderSn }}] to On
        Hold or Cancel status? Once submitted, you cannot modify it again by
        yourself, and must contact our customer service to help modify the order
        status.
      </p>
    </a-modal>
    <a-modal v-model="onholdVisible" @ok="onHoldConfirm">
      <p>
        Please confirm if you need to submit the order [{{ orderSn }}] to On
        Hold or Cancel status? Once submitted, you cannot modify it again by
        yourself, and must contact our customer service to help modify the order
        status.
      </p>
    </a-modal>
    <a-modal
      v-model="logisticsVisible"
      title="Warm reminder"
      @cancel="logisticsCancel"
      @ok="logisticsConfirm"
    >
      <p>{{ logisticsMsg }}</p>
    </a-modal>
  </div>
</template>
<script>
import onePay from '@/components/onePay'
import OrderItem from '@/components/orderItem'
import orderStatusEnum from '@/enums/order/orderStatus'
import {
  orderDetail,
  updateShopping,
  orderCancel,
  onHold,
  calculateForOrder
} from '@/api/order'
import { countrys, provinces } from '@/api/public'
import { createPaypal, executePaypal } from '@/api/pay'
import { mapGetters, mapState } from 'vuex'
export default {
  components: {
    OrderItem,
    onePay
  },
  inject: ['reload'],

  data() {
    return {
      onholdVisible: false,
      cancelVisible: false,
      colorRed: false,
      previewIndex: 0,
      previewList: [], // 预览图片列表
      preViewImg: '', // 预览图片主图
      isPreview: false,
      outerVisible: false,
      loading: false, // loading
      rules: {
        firstName: [
          {
            required: true,
            message: 'First name is required'
          }
        ],
        lastName: [
          {
            required: true,
            message: 'Last name is required'
          }
        ],
        email: [],
        phone: [
          {
            required: true,
            message: 'Phone is required'
          }
        ],
        country: [
          {
            required: true,
            message: 'Country is required'
          }
        ],
        state: [
          {
            required: true,
            message: 'State is required'
          }
        ],
        address: [
          {
            required: true,
            message: 'Address is required'
          },
          { max: 40, message: 'Max length 40' }
        ],
        address2: [],
        city: [
          {
            required: true,
            message: 'City is required'
          },
          { max: 40, message: 'Max length 40' }
        ],
        postal: [
          {
            required: true,
            message: 'ZIP code is required'
          }
        ]
      },
      layout: {
        labelCol: { span: 8 },
        wrapperCol: { span: 14 }
      },
      isShowEditMask: false, // 是否显示编辑弹窗
      countrysList: [], // 国家列表
      countryId: 0, // 国家ID
      provinceList: [], // 省份列表
      orderHisInfos: [],
      orderInfo: {},
      orderItemInfos: [],
      value: 1, // 选择物流方式
      isTimeLine: true, // 是否显示时间线
      timeLineStyle: true,
      orderSn: '',
      orderlistStatus1: [],
      orderlistStatus2: [],
      orderlistStatus3: [],
      orderlistStatus4: [],
      orderlistStatus5: [],
      orderlistStatus6: [],
      ruleForm: {
        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        country: '',
        state: '',
        address: '',
        address2: '',
        city: '',
        postal: ''
      },
      orderId: [],
      buttonStyle: {
        label: 'pay',
        size: 'small',
        shape: 'rect',
        color: 'blue'
      },
      isOnHold: true,
      logisticsVisible: false,
      logisticsMsg: '', // 不支持的快递信息
      calculateForOrderMsg: '', // 价格信息
      logisticsRadio: 1, // 当前选中的物流方式
      logisticsType: 1, // 快递类型1.仅支持小包2.部分支持快递，3.全部支持快递
      priceInfos: [],
      enums: {
        orderStatusEnum
      },
      logisticsMethodId: null,
      // 是否展示订单原始价格
      originPrice: true
    }
  },
  computed: {
    ...mapState({
      MerchantInfo: state => state.User.merchantInfo
    }),
    ...mapGetters('User', {
      paypalEnable: 'getPaypalEnable',
      stripeEnable: 'getStripeEnable'
    }),
    selectPriceInfo() {
      if (!this.logisticsMethodId) {
        return {}
      }
      if (this.originPrice) {
        return {}
      }
      for (const priceInfo of this.priceInfos) {
        if (priceInfo.logisticsMethodId === this.logisticsMethodId) {
          return priceInfo
        }
      }
      return {}
    }
  },
  async mounted() {
    this.loading = true
    this.orderId.push(parseInt(this.$route.query.orderId))
    this.orderSn = this.$route.query.orderSn
    await this.getOrderInfo()
    this.loadCountrys()
    if (this.orderInfo.status === this.enums.orderStatusEnum.PENDING.value) {
      this.calculateOrder()
    }

    if (this.paypalEnable) {
      this.paypalReady()
    }
    this.loading = false
  },
  methods: {
    async loadCountrys() {
      const { data } = await countrys()
      this.countrysList = data.countryInfoList
    },
    async calculateOrder() {
      const { data } = await calculateForOrder({
        orderId: this.$route.query.orderId,
        countryId: this.countryId
      })
      this.priceInfos = data.priceInfos || []
      if (this.priceInfos && this.priceInfos.length) {
        this.logisticsMethodId = this.logisticsMethodId || this.priceInfos[0].logisticsMethodId
      }
    },
    async cancel() {
      this.cancelVisible = true
    },
    async onHold() {
      this.onholdVisible = true
    },
    logisticsCancel() {
      this.logisticsRadio = 1
    },
    async logisticsConfirm() {
      this.logisticsVisible = false
      await this.updateLogistic()
    },
    clearArr() {
      this.orderlistStatus1 = []
      this.orderlistStatus2 = []
      this.orderlistStatus3 = []
      this.orderlistStatus4 = []
      this.orderlistStatus5 = []
      this.orderlistStatus6 = []
    },
    goBack() {
      this.$router.go(-1)
    },
    // 预览图片
    _preViewImg(item) {
      this.previewList = item.imageList
      this.preViewImg = item.imageList[0]
      this.isPreview = true
    },
    // 关闭图片预览
    closePreview() {
      this.previewIndex = 0
      this.isPreview = false
    },
    // 更改预览图
    changePreview(index) {
      this.previewIndex = index
      this.preViewImg = this.previewList[index]
    },
    handlePre() {
      if (this.previewIndex > 0) {
        this.previewIndex = this.previewIndex - 1
        this.preViewImg = this.previewList[this.previewIndex]
      }
    },
    handleNext() {
      console.log('this.previewIndex....', this.previewIndex)
      if (this.previewIndex + 2 <= this.previewList.length) {
        this.previewIndex = this.previewIndex + 1
        this.preViewImg = this.previewList[this.previewIndex]
      }
    },
    paypalReady() {
      const _this = this
      console.warn('paypal ready')
      // eslint-disable-next-line no-undef
      paypal.Button.render(
        {
          env: process.env.VUE_APP_PAY, // sandbox | production
          // Show the buyer a 'Pay Now' button in the checkout flow
          commit: true,
          style: {
            size: 'large',
            color: 'blue',
            shape: 'rect'
          },
          locale: 'en_US',
          // payment() is called when the button is clicked
          payment: function() {
            return _this.ceatePayPals()
          },

          // 支付完成后
          onAuthorize: function(data, actions) {
            console.log(data, actions)
            var datas = {
              paymentId: data.paymentID, // 订单id
              payerId: data.payerID // 支付用户id
            }
            return _this.executePaypals(datas)
          }
        },
        'paypal-button-container2'
      )
    },
    async updateLogistic() {
      if (!this.ruleForm.phone) {
        this.$message.error('Please input Shipping Info[Phone Number]')
        return
      }
      const param = {}
      param.id = this.$route.query.orderId
      param.address1 = this.ruleForm.address
      param.address2 = this.ruleForm.address2
      param.city = this.ruleForm.city
      param.countryId = this.countryId
      param.email = this.ruleForm.email
      param.firstName = this.ruleForm.firstName
      param.phone = this.ruleForm.phone
      param.province = this.ruleForm.state
      param.lastName = this.ruleForm.lastName
      param.zipCode = this.ruleForm.postal
      param.logsiticsType = 3
      param.logisticsMethodId = this.logisticsMethodId
      const { code, msg } = await updateShopping(param)
      if (code !== 200) {
        this.$message.error(msg)
      }
    },
    async getOrderInfo() {
      const { data } = await orderDetail({ orderId: this.$route.query.orderId })
      this.orderHisInfos = data.orderHisInfos
      this.orderInfo = data.orderInfo
      if (data.orderInfo.status === 6) {
        this.isOnHold = false
      }
      this.orderItemInfos = data.orderItemInfos
      this.ruleForm.address = data.orderInfo.addressToAddress1
      this.ruleForm.country = data.orderInfo.addressToCountry
      this.countryId = data.orderInfo.addressToCountryId
      this.ruleForm.email = data.orderInfo.addressToEmail
      this.ruleForm.firstName = data.orderInfo.addressToFirstName
      this.ruleForm.lastName = data.orderInfo.addressToLastName
      this.ruleForm.phone = data.orderInfo.addressToPhone
      this.ruleForm.state = data.orderInfo.addressToRegion
      this.ruleForm.postal = data.orderInfo.addressToZip
      this.ruleForm.address = data.orderInfo.addressToAddress1
      this.ruleForm.address2 = data.orderInfo.addressToAddress2
      this.ruleForm.city = data.orderInfo.addressToCity
      this.logisticsRadio = data.orderInfo.logisticsType
      this.logisticsMethodId = data.orderInfo.logisticsMethodId
      for (let i = 0; i < data.orderItemInfos.length; i++) {
        if (data.orderItemInfos[i].itemStatus === 1) {
          this.orderlistStatus1.push(data.orderItemInfos[i])
        }
        if (data.orderItemInfos[i].itemStatus === 2) {
          this.orderlistStatus2.push(data.orderItemInfos[i])
        }
        if (data.orderItemInfos[i].itemStatus === 3) {
          this.orderlistStatus3.push(data.orderItemInfos[i])
        }
        if (data.orderItemInfos[i].itemStatus === 4) {
          this.orderlistStatus4.push(data.orderItemInfos[i])
        }
        if (data.orderItemInfos[i].itemStatus === 5) {
          this.orderlistStatus5.push(data.orderItemInfos[i])
        }
        if (data.orderItemInfos[i].itemStatus === 6) {
          this.orderlistStatus6.push(data.orderItemInfos[i])
        }
      }
      this.orderlistStatus1 = this.grounp(this.orderlistStatus1)
      this.orderlistStatus2 = this.grounp(this.orderlistStatus2)
      this.orderlistStatus3 = this.grounp(this.orderlistStatus3)
      this.orderlistStatus4 = this.grounp(this.orderlistStatus4)
      this.orderlistStatus5 = this.grounp(this.orderlistStatus5)
      this.orderlistStatus6 = this.grounp(this.orderlistStatus6)
    },
    // 分组
    grounp(arr) {
      var map = {}
      var dest = []
      for (var i = 0; i < arr.length; i++) {
        var ai = arr[i]
        if (!map[ai.waybillId]) {
          // 依赖分组字段可自行更改！
          dest.push({
            waybillId: ai.waybillId, // 依赖分组字段可自行更改！
            data: [ai]
          })
          map[ai.waybillId] = ai // 依赖分组字段可自行更改！
        } else {
          for (var j = 0; j < dest.length; j++) {
            var dj = dest[j]
            if (dj.waybillId === ai.waybillId) {
              // 依赖分组字段可自行更改！
              dest[j].data.push(ai)
              break
            }
          }
        }
      }
      return dest
    },
    // 查询paypal支付是否成功
    async executePaypals(item) {
      const { data } = await executePaypal(item)
      if (data.status === 1) {
        this.$message.success('Pay success')
        this.reload()
      } else {
        this.$message.success(data.failReason)
      }
    },
    // 创建paypal支付
    async ceatePayPals() {
      const { data } = await createPaypal({
        cancelUrl:
          process.env.VUE_APP_API_URL + '/unpay?orderId=' + this.orderId[0],
        orderIds: this.orderId,
        returnUrl:
          process.env.VUE_APP_API_URL + '/unpay?orderId=' + this.orderId[0]
      })
      return data.payId
    },
    async paymentCompleted(data) {
      // 用户支付完成的回调，可以拿到订单id
      console.log(data)
    },
    paymentCancelled(data) {
      // 用户取消交易的回调
      console.log(data)
    },
    // Pay with Credit Card
    async pay() {
      this.$refs.onePay.show({ payType: 2, orderIds: this.orderId })
      this.outerVisible = true
    },
    // 修改物流信息
    submitForm(formName) {
      const _this = this
      this.$refs[formName].validate(async valid => {
        if (valid) {
          await _this.updateLogistic(this.logisticsRadio)
          _this.reload()
        } else {
          _this.$message.error('error submit!')
          return false
        }
      })
    },
    async changeMethod() {
      this.originPrice = false
      await this.updateLogistic()
      this.reload()
    },
    timeLine() {
      if (this.isTimeLine === true) {
        this.isTimeLine = false
        this.timeLineStyle = { height: '0px', overflow: 'hidden' }
      } else {
        this.isTimeLine = true
        this.timeLineStyle = { height: 'auto', overflow: 'hidden' }
      }
    },
    doPayPal() {
      console.warn(document.getElementById('paypal-button-container2'))
      document.getElementById('paypal-button-container2').click()
    },
    // 选择国家
    async choiceCountrys(e) {
      this.ruleForm.state = ''
      this.countryId = e
      this.provinceList = []
      const { data } = await provinces({ countryId: e })
      this.provinceList = data.provinceInfoList
      console.log(data)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    // 选择省份
    async choiceProvince(e) {
      this.ruleForm.state = e
    },
    // 确认提交问题订单
    async onHoldConfirm() {
      const id = parseInt(this.$route.query.orderId)
      const { code } = await onHold({ orderId: id })
      if (code === 200) {
        this.reload()
      } else {
        this.$message.error('fail')
      }
      this.onholdVisible = false
    },
    // 确认取消订单
    async cancelConfirm() {
      const id = parseInt(this.$route.query.orderId)
      const { code } = await orderCancel({ orderId: id })
      if (code === 200) {
        this.reload()
      } else {
        this.$message.error('fail')
      }
      this.cancelVisible = false
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../assets/css/preview.scss";
.design-mask{
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: rgba(0,0,0,0.5);
  .design-dialog{
    position: absolute;
    top: 50%;
    left: 50%;
    margin-top: -293px;
    margin-left: -322px;
    width: 644px;
    height: 606px;
    border-radius: 8px 8px 8px 8px;
    background-color: #fff;
    .design-close{
      position: absolute;
      top: 10px;
      right: 10px;
      img{
        width: 30px;
        height: 30px;
        cursor: pointer;
      }
    }
    .dialog-main{
      width: 100%;
      height: 460px;
      display: flex;
      align-items: center;
      margin-top: 30px;
      .dialog-left{
        width: 92px;
        height: 460px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .dialog-right{
        width: 92px;
        height: 460px;
        display: flex;
        align-items: center;
        cursor: pointer;
      }
      .dialog-con{
        width: 460px;
        img{
          width: 460px;
          border-radius: 12px;
        }
      }
    }
    .dialog-pages{
      padding: 20px;
      width: 644px;
      .dialog-page{
        display: flex;
        overflow: hidden;
        overflow-x: scroll;
        padding-bottom: 10px;
        img{
          width: 60px;
          height: 60px;
          border-radius: 8px;
          margin-right: 8px;
          border: 2px solid #fff;
          &:hover{
            border: 2px solid #000;
          }
          &.actived{
            border: 2px solid #000;
          }
        }
      }
    }
  }
}
.color-red {
  color: red;
}
.paypal {
  position: relative;
  width: 100%;
  height: 30px;
  button {
    border-radius: 2px;
    background-color: rgb(64, 158, 255, 1);
    font-style: italic;
    width: 200px;
    height: 30px;
    color: white;
  }
  #paypal-button-container2 {
    position: absolute;
    top: 0;
    height: 30px;
    width: 200px;
    opacity: 0;
  }
}
.pay-btn {
  width: 200px;
  height: 30px;
  border-radius: 2px;
  background: #0896d5;
  font-style: italic;
}
.bg-gray {
  background: rgba(243, 243, 243, 1);
  min-height: 100vh;
}
.back {
  display: flex;
  align-items: center;
  font-size: 28px;
  max-width: 1168px;
  margin: 0 auto;
  padding-top: 47px;
  margin-bottom: 20px;
  .cancel {
    margin-left: auto;
  }
  i {
    cursor: pointer;
  }
  span {
    margin-left: 20px;
    font-size: 28px;
  }
}
.shopMethod p{
  color: #f34547;
}
.unpay {
  max-width: 1168px;
  padding: 16px;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: white;
  .p-head {
    display: flex;
    .h-title {
      font-size: 20px;
      font-family: "SourceHanSansSC-regular";
    }
    .s-title {
      margin-left: auto;
      width: 340px;
      font-size: 20px;
      font-family: "SourceHanSansSC-regular";
      i {
        cursor: pointer;
      }
    }
  }
  .edit-shopping {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10;
    .edit-warp {
      padding: 50px;
      width: 900px;
      background-color: white;
      margin: 0 auto;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 50%;
      margin-left: -450px;
    }
  }
  .step {
    text-align: left;
    margin-top: 29px;
    .timeline {
      display: inline-block;
      background: #409eff;
      color: white;
      padding: 10px 30px;
      margin: 30px 0;
    }
    .ant-timeline {
      margin-top: 5px;
    }
  }
  .h-title {
    font-size: 25px;
  }
  .h-store {
    float: left;
    margin-left: 30px;
    margin-top: 8px;
  }
  .h-time {
    float: right;
    font-size: 18px;
    text-align: right;
    margin-right: 0;
    margin-top: 8px;
  }
  .content {
    width: 100%;
    display: flex;
    position: relative;
    margin-top: 10px;
    .product {
      flex: 1;
      position: relative;
      margin-right: 79px;
      .product-list {
        position: relative;
        margin-bottom: 30px;
      }
      .statu {
        width: 200px;
        height: 40px;
        border-radius: 2px;
        position: absolute;
        top: 0px;
        right: 20px;
        padding-left: 50px;
        padding-right: 50px;
      }
    }
    .shop {
      width: 340px;
      margin-left: auto;
      .s-title {
        text-align: left;
        font-size: 25px;
        i {
          float: right;
        }
      }
      ul li {
        margin-top: 10px;
        text-align: left;
        font-size: 14px;
        display: flex;
        span:nth-child(1) {
          text-align: left;
          display: inline-block;
          width: 161px;
          font-size: 14px;
          font-weight: 200;
        }
        span:nth-child(2) {
          font-size: 14px;
          margin-right: auto;
          word-wrap: break-word;
        }
      }
      .sm-title {
        font-size: 18px;
        margin-top: 20px;
      }
    }
  }
  .timeLine {
    padding-top: 20px;
  }
  .pending {
    background-color: #ffca87;
    border: 1px solid #ffca87;
    color: #f34547;
  }
  .confirmed {
    background-color: #caebfc;
    border: 1px solid #caebfc;
    color: #00acfc;
  }
  .partially {
    background-color: #d3e8d5;
    border: 1px solid #d3e8d5;
    color: #23992f;
  }
  .fulfilled {
    background-color: #23992f;
    border: 1px solid #23992f;
    color: white;
  }
  .onhold {
    background-color: #ffe5c2;
    border: 1px solid #ffe5c2;
    color: #ff6134;
  }
}
</style>
