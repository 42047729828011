<template>
  <div v-if="isShow" class="card-mask">
    <!-- 一次性支付页面 -->
    <div class="card-content">
      <div class="c-title">
        <span>Add a credit card to your account</span>
        <i class="close" @click.stop="closeOrder">
          <a-icon type="close" />
        </i>
      </div>
      <div id="card-element" />
      <p id="card-error" role="alert">{{ bankError }}</p>
      <re-captcha :sitekey="recaptchaKey" @getValidateCode="onRecaptchaCodeUpdate" />
      <a-button
        id="submit"
        :disabled="!recaptchaCode"
        type="primary"
        size="large"
        :loading="iconLoading"
        @click="submit('ruleForm')"
      >
        <div id="spinner" class="spinner hidden" />
        <span id="button-text">Submit</span>
      </a-button>
      <!-- </a-form-model-item>
      </a-form-model>-->
      <div class="bottom1">
        <a-icon type="lock" />
        <span>lockSecure card form</span>
      </div>
      <div class="bottom2">
        <span>Credit cards handled securely with SSL encryption</span>
      </div>
    </div>
  </div>
</template>
<script>
// import Stripe from "../assets/js/stripemin";
// success  4242 4242 4242 4242  05 27 946 88968
// require  4000 0025 0000 3155
// payment  4000 0000 0000 9995
import {
  createPaymentIntentStatus,
  publicKey,
  createPaymentIntent, recaptchaValid
} from '@/api/pay'
import { countrys } from '@/api/public'
import ReCaptcha from '@/components/ReCaptcha'
export default {
  components: {
    ReCaptcha
  },
  data() {
    return {
      recaptchaKey: process.env.VUE_APP_RECAPTCHAkEY, // recaptcha的key
      stripePaymentIntentId: '', // stripe支付意向ID,轮询查询接口时候使用
      clientSecret: '', // clientSecret, stripe接口使用
      key: '',
      stripe: '',
      card: '',
      elements: '',
      isShow: false,
      orderIds: [],
      payType: null,
      ruleForm: {
        card: '',
        name: '',
        country: '',
        region: '',
        address1: '',
        address2: '',
        city: '',
        zip: ''
      },
      countryCode: '',
      rules: {
        card: [
          {
            required: true,
            message: 'Card details required'
          }
        ],
        name: [
          {
            required: true,
            message: 'Cardholder name'
          }
        ],
        country: [
          {
            required: true,
            message: 'Country is required'
          }
        ],
        region: [],
        address1: [
          {
            required: true,
            message: 'address is required'
          }
        ],
        address2: [],
        city: [
          {
            required: true,
            message: 'City is required'
          }
        ],
        zip: [
          {
            required: true,
            message: 'Zip code is required'
          }
        ]
      },
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 16 }
      },
      iconLoading: false, // 按钮加载中的错误信息
      countrysList: [], // 国家列表
      bankError: '', // 银行卡输入时的错误信息
      isErr: false, // 是否显示提交的错误信息
      errorMsg: '', // 提交的错误信息
      recaptchaCode: null
    }
  },
  methods: {
    async show({ payType, orderIds }) {
      this.isShow = true
      this.payType = payType
      this.orderIds = orderIds
      const { data } = await countrys()
      this.countrysList = data.countryInfoList
      this.getPublicKey()
    },
    onRecaptchaCodeUpdate(v) {
      this.recaptchaCode = v
    },
    // 获取公钥
    async getPublicKey() {
      const { data } = await publicKey()
      this.key = data
      this.getSetupIntent()
    },
    // 获取支付意向
    async getSetupIntent() {
      const { data } = await createPaymentIntent({
        orderIds: this.orderIds
      })
      this.clientSecret = data.clientSecret
      this.stripePaymentIntentId = data.stripePaymentIntentId
      this.stripeElements()
    },
    // 配置stripe
    stripeElements() {
      // eslint-disable-next-line no-undef
      this.stripe = Stripe(this.key)
      this.elements = this.stripe.elements()
      var style = {
        base: {
          color: '#32325d',
          fontFamily: 'Arial, sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
            color: '#32325d'
          }
        },
        invalid: {
          fontFamily: 'Arial, sans-serif',
          color: '#fa755a',
          iconColor: '#fa755a'
        }
      }
      this.card = this.elements.create('card', {
        style: style,
        hidePostalCode: true
      })
      this.card.mount('#card-element')
      // Element focus ring
      this.card.on('focus', function() {
        var el = document.getElementById('card-element')
        el.classList.add('focused')
      })

      this.card.on('blur', function() {
        var el = document.getElementById('card-element')
        el.classList.remove('focused')
      })
    },
    // 这步应该是去轮询查询stripe配置结果
    async orderComplete() {
      const _this = this
      _this.iconLoading = true
      setTimeout(async() => {
        const { data } = await createPaymentIntentStatus({
          stripePaymentIntentId: _this.stripePaymentIntentId
        })
        if (data.status === 1) {
          _this.orderComplete()
        } else if (data.status === 3) {
          _this.$parent.pullList()
          _this.isErrorMsg = data.failReason
          _this.$parent.outerVisible = false
          _this.$parent.isShowCreateOrder = true
          _this.$parent.closeOrderMask()
        } else if (data.status === 2) {
          if (_this.payType === 1) {
            _this.$parent.pullList()
            _this.$parent.outerVisible = false
            _this.$parent.isShowCreateOrder = true
            _this.$parent.closeOrderMask()
            _this.iconLoading = false
          } else if (_this.payType === 2) {
            _this.$parent.outerVisible = false
            _this.$parent.getOrderInfo()
          } else {
            _this.$parent.getOrderPage()
            _this.$parent.paySuccessTip()
            _this.$parent.isOnePay2 = false
          }
        }
      }, 500)
    },
    // 提交到stripe，查询配置是否成功
    async submit() {
      if (!this.recaptchaCode) {
        this.$message.error('Please verify reCaptcha')
        return
      }
      try {
        await recaptchaValid({
          recaptchaToken: this.recaptchaCode
        })
      } catch (e) {
        window.grecaptcha.reset()
        this.recaptchaCode = null
      }

      const _this = this
      _this.bankError = ''
      // this.$refs[formName].validate(valid => {
      //   if (valid) {
      this.iconLoading = true
      this.stripe
        .confirmCardPayment(this.clientSecret, {
          payment_method: {
            card: this.card,
            // billing_details: {
            //   address: {
            //     city: _this.ruleForm.city,
            //     country: _this.ruleForm.country,
            //     line1: _this.ruleForm.address1,
            //     line2: _this.ruleForm.address2,
            //     postal_code: _this.ruleForm.zip,
            //     state: _this.ruleForm.region
            //   },
            //   name: _this.ruleForm.name
            // },
            // clientSecret: false,
            type: 'card'
          }
        })
        .then(function(result) {
          console.log(result)
          if (result.error) {
            _this.bankError = result.error.message
            _this.iconLoading = false
            window.grecaptcha.reset()
            _this.recaptchaCode = null
          } else {
            _this.orderComplete()
          }
        })
      //   }
      // });
    },
    // 关闭弹窗
    closeOrder() {
      const _this = this
      _this.isShow = false
      if (_this.payType === 1) {
        _this.$parent.pullList()
        _this.$parent.outerVisible = false
        _this.$parent.isShowCreateOrder = true
        _this.$parent.closeOrderMask()
        _this.iconLoading = false
      } else if (_this.payType === 2) {
        _this.$parent.clearArr()
        _this.$parent.outerVisible = false
        _this.$parent.getOrderInfo()
      } else {
        _this.$parent.orderList = []
        _this.$parent.getOrderPage()
        // _this.$parent.paySuccessTip();
        _this.$parent.isOnePay2 = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
@import "../assets/css/global.css";
.card-mask {
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  height: 100%;
  width: 100%;
  z-index: 10;
  .card-content {
    width: 640px;
    background: white;
    margin: 0 auto;
    padding: 24px;
    position: relative;
    transform: translateY(-50%);
    top: 50%;
    .bottom1 {
      text-align: center;
      font-size: 16px;
      color: #106ebe;
      span {
        margin-left: 10px;
      }
    }
    .bottom2 {
      text-align: center;
      font-size: 13px;
      color: #949494;
    }
    .c-titles {
      margin-bottom: 20px;
      margin-top: 10px;
    }
    .c-title {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      span {
        flex: 1;
        font-size: 19px;
      }
      .close {
        color: #333;
        cursor: pointer;
      }
    }
    #card-element {
      border: 1px solid var(--gray-border);
      border-radius: var(--radius);
      padding: 5px 12px;
      height: 44px;
      width: 100%;
      transition: box-shadow 0.2s ease;
      background: white;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      padding-top: 10px;
    }
  }
}
</style>
