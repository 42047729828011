<template>
  <div>
    <div v-for="(item, index) in items.data" :key="index">
      <div class="list">
        <div class="l-left">
          <img :src="item.imageUrl" srcset @click="preViewImg(item)">
          <span>{{ item.quantity }}</span>
        </div>
        <div class="l-right">
          <p class="l-title ellipsis">
            {{ item.customProductTitle }}{{ item.itemStatus }}
          </p>
          <div class="l-style">
            <span
              v-show="item.option1Name"
            >{{ item.option1Name }}:{{ item.option1Value }}</span>
            <span
              v-show="item.option2Name"
            >{{ item.option2Name }}:{{ item.option2Value }}</span>
            <span
              v-show="item.option3Name"
            >{{ item.option3Name }}:{{ item.option3Value }}</span>
          </div>
          <div class="l-sku l-style">
            Sku:{{ item.sku }} | Quantity: {{ item.quantity }}
          </div>
          <div class="l-cost l-style">
            Product cost:${{ item.goodsTotalPrice }}
          </div>
        </div>
      </div>
      <div v-if="(item.imageBoxInfoList && item.imageBoxInfoList.length > 0) || (item.inputBoxInfoList && item.inputBoxInfoList.length > 0)">
        <div class="d-flex fontBold font20 mt10" style="margin-bottom: 10px">Custom File</div>
        <template v-if="item.imageBoxInfoList && item.imageBoxInfoList.length > 0">
          <div v-for="(imageBox,imageBoxIndex) in item.imageBoxInfoList" :key="imageBox.name+imageBoxIndex">
            <div class="fontBold font16">{{ imageBox.name }}</div>
            <div class="d-flex flex-warp mt15">
              <img v-if="imageBox.imageUrl" class="viewImg" :src="imageBox.imageUrl">
            </div>
          </div>
        </template>
        <template v-if="item.inputBoxInfoList && item.inputBoxInfoList.length > 0">
          <div v-for="(inputBox,inputBoxIndex) in item.inputBoxInfoList" :key="inputBox.name+inputBoxIndex">
            <div class="fontBold font16">
              {{ inputBox.name }}
            </div>
            <div class="mt10 mb10">{{ inputBox.text }}</div>
          </div>
        </template>

      </div>
      <div v-if="item.fittingsInfoList" class="branding">
        <div class="brand-title">
          Branding
        </div>
        <div class="brand-boxs">
          <div v-for="(fittingsItem,fittingsIndex) in item.fittingsInfoList" :key="fittingsIndex" class="brand-box">
            <div class="brand-box-img">
              <img :src="fittingsItem.mainImageUrl" alt="">
            </div>
            <div class="brand-con">
              <div class="barnd-name">{{ fittingsItem.title }}</div>
              <div class="barnd-price">Product cost：${{ fittingsItem.goodsTotalPrice }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="item.data[0].shippingMethod" class="shopping">
      <h1 class="s-title">Tracking details</h1>
      <p class="shop-item">
        Shipping method: {{ item.data[0].shippingMethod }}
      </p>
      <p class="shop-item">
        Tracking number: {{ item.data[0].trackingNumber }}
      </p>
      <p class="shop-item">Tracking url: {{ item.data[0].trackingUrl }}</p>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    item: {
      type: Object
    }
  },
  data() {
    return {
      items: []
    }
  },
  watch: {
    item: {
      immediate: true,
      handler(val) {
        this.items = val
      }
    }
  },
  mounted() {},
  methods: {
    preViewImg(item) {
      this.$parent._preViewImg(item)
    }
  }
}
</script>
<style lang="scss" scoped>
.shopping {
  margin-top: 20px;
  font-family: "SourceHanSansSC-regular";
  .s-title {
    font-size: 16px;
    font-family: "SourceHanSansSC-regular";
  }
  .shop-item {
    font-size: 16px;
    font-family: "SourceHanSansSC-regular";
  }
}
.branding{
  margin-top: 24px;
  .brand-title{
    font-size: 18px;
    font-weight: 400;
    color: #333;
  }
  .brand-boxs{
    display: flex;
    align-items: center;
    margin-top: 12px;
    .brand-box{
      display: flex;
      align-items: center;
      border-radius: 7px 7px 7px 7px;
      border: 1px solid #EFEFEF;
      padding: 14px 12px;
      margin-right: 23px;
      .brand-box-img{
        border: 1px solid #DADADA;
        width: 46px;
        height: 46px;
        img{
          width: 46px;
          height: 46px;
        }
      }
      .brand-con{
        margin-left: 12px;
        .barnd-name{
          font-size: 14px;
          font-weight: 400;
        }
        .brand-price{
          font-size: 12px;
          color: #777;
        }
      }
    }
  }
}
.list {
  display: flex;
  margin-top: 20px;
  position: relative;
  button {
    position: absolute;
    top: 10px;
  }
  .l-left {
    width: 100px;
    height: 100px;
    margin-right: 30px;
    position: relative;
    cursor: pointer;
    img {
      width: 100%;
      height: 100%;
    }
    span {
      display: inline-block;
      color: white;
      width: 20px;
      line-height: 20px;
      height: 20px;
      text-align: center;
      font-size: 14px;
      position: absolute;
      bottom: 0px;
      right: 0px;
      background-color: rgba(0, 0, 0, 0.5);
    }
  }
  .l-right {
    margin-right: 0;
    text-align: left;
    .l-title {
      font-size: 18px;
      margin-bottom: 0px;
      font-family: "SourceHanSansSC-regular";
      width: 150px;
    }
    .l-style {
      margin-top: 10px;
      font-size: 13px;
      line-height: 13px;
      color: #999;
      span {
        display: inline-block;
        padding: 0 10px;
        border-right: 1px solid #ddd;
      }
      span:last-child {
        border-right: 0;
      }
      span:first-child {
        padding-left: 0;
      }
    }
  }
}
.viewImg{
  width: 100px;
  height: 100px;
  margin-right: 10px;
  margin-bottom: 10px;
}
</style>
